<template>
    <v-container fluid class="d-flex flex-column align-stretch parent-container">
        <div class="search">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Search"
                            v-model="search"        
                            dense
                            clearable filled solo hide-details
                            prepend-inner-icon="mdi-text-search"
                            width="50px" 
                            class="elevation-8 pt-0">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="d-flex tree-view-container flex-grow-1">
           <template v-if="taxonomies">
                <v-treeview
                    ref="treeView"
                    hoverable
                    transition
                    :filter="filterTaxonomies"
                    :items="taxonomies.pre"
                    item-children="concepts"
                    item-text="label"
                    :search="search"
                    :open.sync="openNodes">
                    <template #prepend="{item}">
                        <uv-button small :key="item.id" @click.stop="openDialog(item)" color="success" v-if="!conceptIsAbstract(item) && !showEditAndDeleteButtons(item)" :disabled="isReadOnly" icon title="Apply Tag">
                            <v-icon>mdi-plus-box</v-icon>
                        </uv-button>
                        <uv-button small @click.stop="openDialog(item, true)" color="success" v-if="showContinueButton(item) && !showEditAndDeleteButtons(item)" :disabled="isReadOnly" icon title="Continue Tag">
                            <v-icon>mdi-plus-box-multiple</v-icon>
                        </uv-button>
                        <uv-button small :key="item.id" @click.stop="handleClickOnRemoveTagBtn(item)" color="error" v-if="showEditAndDeleteButtons(item)" :disabled="isReadOnly" icon title="Delete Tag">
                            <v-icon>mdi-minus-box</v-icon>
                        </uv-button>
                        <uv-button small @click.stop="openDialog(item)" color="warning" v-if="showEditAndDeleteButtons(item)" :disabled="isReadOnly" icon title="Edit Aspects">
                            <v-icon>mdi-pencil-box</v-icon>
                        </uv-button>
                        <!-- daca sunt putine butoane, le afisam pe toate -->
                        <template v-if="getXbrlIdsByConceptId(item.id)?.length <= 3">
                            <uv-button v-for="goToButtonId of getXbrlIdsByConceptId(item.id)" :color="currentSelectedContinuation(goToButtonId) && showEditAndDeleteButtons(item) ? 'warning' : 'blue' " :key="`item-goto-${goToButtonId}`" small icon title="Go to tag"
                                @click="goToTag(goToButtonId)">
                                    <v-icon>mdi-arrow-bottom-left-thick</v-icon>
                            </uv-button>
                        </template>
                        <!-- daca sunt mai multe, afisam primele 3 si pe restul intr-un meniu -->
                        <template v-else>
                            <uv-button v-for="goToButtonId of getXbrlIdsByConceptId(item.id).slice(0,3)" :color="currentSelectedContinuation(goToButtonId) && showEditAndDeleteButtons(item) ? 'warning' : 'blue' " :key="`item-goto-${goToButtonId}`" small icon title="Go to tag"
                                @click="goToTag(goToButtonId)">
                                    <v-icon>mdi-arrow-bottom-left-thick</v-icon>
                            </uv-button>
                            <v-menu>
                                <template v-slot:activator="{ on }">
                                    <uv-button small icon  v-on="on" title="More links">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </uv-button>
                                </template>
                                <v-list dense>
                                    <v-list-item 
                                        v-for="goToButtonId of getXbrlIdsByConceptId(item.id).slice(3)"
                                        :key="`item-goto-${goToButtonId}`"
                                        @click="goToTag(goToButtonId)">
                                        <v-list-item-content>
                                            <v-list-item-icon>
                                                <v-icon title="Go to tag" :color="currentSelectedContinuation(goToButtonId) && showEditAndDeleteButtons(item) ? 'warning' : 'blue'">
                                                    mdi-arrow-bottom-left-thick
                                                </v-icon>
                                            </v-list-item-icon>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        
                    </template>
                    <template #label="{item,attrs,on}">
                        <div
                            :id="`id-${item.id}`"
                            class="itemLabel"
                            v-bind="attrs"
                            v-on="on" 
                            @click.stop="selectedId = item.id">
                            {{item.label}}
                        </div>
                    </template>
                </v-treeview>
            </template>
            <v-overlay
                :absolute="true"
                :value="selectedConcept != null"
                color="white"
                opacity="1">
                <v-btn @click="closeDetailsMenu" icon outlined><v-icon color="black">mdi-arrow-left</v-icon></v-btn>
                <v-card flat color="white" style="max-height: 95%;overflow-y:auto">
                    <v-card-text>
                        <template v-for="(label,info) of selectedConcept">
                            <v-row dense :key="info" class="infoContainer" v-if="info!='references' && info!='displayRefs'">
                                <v-col style="flex-grow: 0.3;">
                                    <span class="blackText"><strong>{{capitalizeFirstLetter(info)}}</strong></span>
                                </v-col>
                                <v-col style="flex-grow: 0.7;">
                                    <span class="blackText">{{label}}</span>
                                </v-col>
                            </v-row>
                            <template v-else-if="info=='references'"></template>
                            <div class="infoContainer" style="overflow: unset;" :key="`${info}-ref`" v-else-if="info=='displayRefs' && Object.keys(label)?.length>0">
                                <v-row dense >
                                    <v-col style="flex-grow: 0.3;">
                                        <span class="blackText"><strong>References</strong></span>
                                    </v-col>
                                </v-row>
                                <template v-for="(details,ref) of label">
                                    <v-layout :key="ref" justify-space-between align-center @click="details.expanded=!details.expanded" style="cursor: pointer;">
                                        <span class="blackText ml-2" style="text-transform: none;">{{capitalizeFirstLetter(ref)}}</span>
                                        <v-icon color="black">
                                            <template v-if="!details.expanded">mdi-menu-down</template>
                                            <template v-else>mdi-menu-up</template>
                                        </v-icon>
                                    </v-layout>
                                    <v-expand-transition :key="ref">
                                        <div v-if="details.expanded">
                                            <v-row v-for="detail in details.details" :key="detail[0]" dense class="ml-4">
                                                <v-col style="flex-grow: 0.3;">
                                                    <span class="blackText"><strong>{{capitalizeFirstLetter(detail[0])}}</strong></span>
                                                </v-col>
                                                <v-col style="flex-grow: 0.7;">
                                                    
                                                    <span v-if="detail[0]!='URI'" class="blackText">{{detail[1]}}</span>
                                                    <a v-else :href="detail[1]" target="_blank">{{detail[1]}}</a>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-expand-transition>
                                </template>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
        </v-overlay>
            
        </div>
        <v-dialog
            :value="dialog"
            max-width="40%"
            max-heigth="50%"
            persistent>
                <v-card>
                    <v-card-title>{{selectedItem.label}}</v-card-title>
                    <v-card-text>
                        <v-container fluid> 
                            <v-row> 
                                <v-col cols="4">
                                    <uv-select
                                        :items="selectorItems.factTypes"
                                        v-model="factData.type"
                                        label="Fact Type"
                                        @change="resetUnits"    
                                        >
                                        
                                    </uv-select>
                                </v-col> 
                                <v-col cols="4" v-if="!factData.continue">
                                    <uv-select
                                        v-model="factData.period"
                                        label="Period"
                                        :items="reportInfo.periods"
                                        item-text="name"
                                        :placeholder="reportInfo.period.toLowerCase()"
                                        persistent-placeholder
                                        clearable
                                        :disabled="closestParentIsTableRow || isCellSelection">
                                    </uv-select>
                                </v-col>
                                <v-col cols="4" v-if="!factData.continue">
                                    <uv-select
                                        v-model="factData.periodType"
                                        label="Period Type"
                                        :items="selectorItems.periodType"
                                        :placeholder="reportInfo.period_type.toLowerCase()"
                                        persistent-placeholder
                                        clearable
                                        >
                                    </uv-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="!factData.continue">
                                <v-col cols="4">
                                    <uv-text-field
                                        v-model="factData.decimals"
                                        label="Decimals"
                                        type="number"
                                        :placeholder="reportInfo.decimals.toString()"
                                        persistent-placeholder
                                        :disabled="isTextTypeOrTextBlockSelected"
                                        clearable>
                                    </uv-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <uv-text-field
                                        v-model="factData.numericScale"
                                        label="Numeric Scale"
                                        type="number"
                                        :placeholder="reportInfo.numeric_scale.toString()"
                                        persistent-placeholder
                                        :disabled="isTextTypeOrTextBlockSelected"
                                        clearable>
                                    </uv-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <uv-select
                                        v-model="factData.numericTransform"
                                        label="Numeric Transform"
                                        :items="selectorItems.numericTransform"
                                        :placeholder="reportInfo.numeric_transform.toLowerCase()"
                                        persistent-placeholder
                                        :disabled="isTextTypeOrTextBlockSelected"
                                        clearable>
                                    </uv-select>                                    
                                    
                                </v-col>
                            </v-row>
                            <v-row v-if="!factData.continue">
                                <v-col v-if="!usedUnit || usedUnit == 1">
                                    <uv-autocomplete
                                        v-model="factData.monetaryUnit"
                                        label="Monetary Unit"
                                        :items="monetaryUnits"
                                        item-value="id"
                                        item-text="name"
                                        :placeholder="reportInfo.monetary_units"
                                        persistent-placeholder
                                        clearable
                                        :filter="findUnit"
                                        :return-object="true"
                                        @change="(val) => onUnitChange(val, 'monetary')"
                                        :disabled="(usedUnit && usedUnit != 1) || factData.type != 'monetary'">
                                        
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.id}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                    <v-icon>mdi-information-variant</v-icon>
                                                </uv-button>
                                            </v-list-item-action>
                                        </template>
                                        <template #selection="{item}">
                                            {{item.id}}
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col v-if="!usedUnit || usedUnit == 2">
                                    <uv-autocomplete
                                        v-model="factData.unit"
                                        label="Unit"
                                        :items="unitsItems"
                                        item-value="id"
                                        item-text="name"
                                        eager
                                        clearable
                                        :filter="findUnit"
                                        :return-object="true"
                                        @change="(val) => onUnitChange(val, 'unit')"
                                        :disabled="(usedUnit && usedUnit != 2) || factData.type != 'num'">
                                        
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.id}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                    <v-icon>mdi-information-variant</v-icon>
                                                </uv-button>
                                            </v-list-item-action>
                                        </template>
                                        <template #selection="{item}">
                                            {{item.id}}
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col v-if="!usedUnit || usedUnit == 3">
                                    <uv-autocomplete
                                        class="complex-unit-selector"
                                        v-model="factData.complexUnit"
                                        label="Complex Unit"
                                        :items="reportInfo.complex_units"
                                        item-value="name"
                                        item-text="name"
                                        eager
                                        clearable
                                        :filter="findUnit"
                                        @change="(val) => onUnitChange(val, 'complex')"
                                        :disabled="(usedUnit && usedUnit != 3) || factData.type != 'num'">
                                
                                        <template #item="{item}">
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                <v-list-item-subtitle>{{item.numerator}} per {{item.denominator}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                        <template #append>
                                            <uv-button small color="info" icon title="Add Complex Unit" @click.stop="displayAddComplexUnitDialog = true" :disabled="(usedUnit && usedUnit != 3) || factData.type != 'num'">
                                                    <v-icon>mdi-plus-thick</v-icon>
                                            </uv-button>
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                
                            </v-row>
                            <v-row v-if="!factData.continue">
                                <!-- <v-col cols="4">
                                    <v-switch
                                        class="sign-switch"
                                        v-model="factData.continue"
                                        label="Continuation">
                                    </v-switch>
                                </v-col>  --> 
                                <v-col cols="6">
                                    <v-switch
                                        class="sign-switch"
                                        v-model="factData.reverseSign"
                                        :disabled="isTextTypeOrTextBlockSelected"
                                        label="Reverse fact value sign">
                                    </v-switch>
                                </v-col> 
                            </v-row>

                            <v-card v-if="!factData.continue" outlined tag="fieldset" elevation=1>
                                <legend>
                                    <v-layout>
                                        <label class="pa-2 v-label font-weight-medium info--text">Dimensions</label>
                                        <v-spacer></v-spacer>
                                        <uv-button icon color="success" title="Add dimension" @click="addDimension">
                                            <v-icon>mdi-plus</v-icon>
                                        </uv-button>
                                    </v-layout>
                                </legend>
                                
                                <template v-for="(dimension, idx) of factData.dimensions">
                                    <v-divider v-if="idx > 0" :key="`div-${idx}`"></v-divider>
                                    <v-row class="px-1" dense  :key="`dim-${idx}`">
                                        <v-col cols="12">
                                            <v-autocomplete
                                                label="Dimension Name"
                                                v-model="dimension.dimensionTagName"
                                                @change="dimension.dimensionTagValue = ''"
                                                :items="dimensionNameItems"
                                                item-text="text"
                                                clearable>
                                                <template #append-outer>
                                                    <uv-button icon color="error" title="Remove dimension" @click="removeDimension(idx)">
                                                        <v-icon>mdi-minus</v-icon>
                                                    </uv-button>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col v-if="dimension.dimensionTagName != '' && dimension.dimensionTagName != null && dimension.dimensionTagName != 'total'">
                                            <v-autocomplete
                                            label="Dimension Value"
                                            v-model="dimension.dimensionTagValue"
                                            :items="dimensionsDict[dimension.dimensionTagName]"
                                            clearable>
                                                <template #append-outer>
                                                    <span style="width:36px"></span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-card>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn
                                color="grey darken-1"
                                text
                                @click="closeDialog">
                            Discard
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="$emit('onApplyTag',selectedItem.id, factData, editMode, selectedMark, selectedBlock);closeDialog()">
                                Apply
                            </v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
        <slot></slot>

        <uv-confirm-dialog ref="newNamespaceConfirmDialog">
            <span class="font-weight-bold">{{newNamespaceConfirmDialogText}}</span>
        </uv-confirm-dialog>
        
        <v-dialog :value="displayNamespaceDialog" max-width="500px">
            <add-namspace-form ref="form" 
                :newUri="newNamespaceUri" 
                @close="displayNamespaceDialog = false; clearMonetaryUnit()"
                @done="displayNamespaceDialog = false; clearMonetaryUnit()">
            </add-namspace-form>
        </v-dialog>
        <v-dialog :value="displayAddComplexUnitDialog"
            persistent
            width="50%">
                 <complex-unit-form :report="reportInfo" @ok="addNewComplexUnit" @cancel="displayAddComplexUnitDialog = false"></complex-unit-form>
        </v-dialog>
    </v-container>
    
</template>

<script>
import { TextSelection } from 'prosemirror-state';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { pickBy } from "lodash/object";
import { default as UvConfirmDialog } from "./ConfirmDialog";
import ComplexUnitForm from "@/views/Reports/ComplexUnitForm";
import { findParentNodeClosestToPos } from '@tiptap/core';
import { NodeSelection } from 'prosemirror-state';
import AddNamspaceForm from './AddNamspaceForm.vue';
import { dimensionsMixin } from "@/plugins/mixins"
export default {
    name: "TaxonomiesMenu",
    mixins: [dimensionsMixin],
    components: {
        UvConfirmDialog,
        "complex-unit-form" : ComplexUnitForm,
        "add-namspace-form" : AddNamspaceForm,
    },
    props:{
        isReadOnly: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedId: null,
            search: "",
            filteredElements: [],
            displayDetailsMenu: false,
            openNodes: [],
            dialog: false,
            usedUnit: null,
            
            selectorItems: {
                periodType: ['instant','instant-start','duration'],
                numericTransform: ['num-dot-decimal','num-comma-decimal','num-unit-decimal'],
                factTypes: [{header: "Inline tags"}, {text: "Text", value: "string"}, {text: "Numeric", value: "num"}, {text: "Monetary", value: "monetary"}, { divider: true }, { header: "Block tags"}, {text: "TextBlock", value: "block"}]
            },
            factData: {
                reverseSign: false,
                period: "",
                periodType: "",
                decimals: null,
                numericScale: null,
                monetaryUnit: null,
                unit: null,
                complexUnit: null,
                numericTransform: "",
                type: "",
                continue: false,
                dimensions: [],
            },
            selectedItem: {},
            editMode: false,
            selectedMark: '',
            selectedBlock: '',
            newNamespaceConfirmDialogText: "Namespace of unit is not saved in the report. \nDo you wish to add it ?",
            displayNamespaceDialog: false,
            newNamespaceUri: "",
            newNamespaceName: "",
            isAddingNewNamespace: false,
            displayAddComplexUnitDialog: false
        }
    },
    created() {
        this.highlightedItems = [];
    },
    computed: {
        ...mapState("xbrlData" ,[
            "taxonomies",
            "editorSelection",
            "selectionAttributes",
            "reportTags",
            "reportInfo"
        ]),
        ...mapGetters("xbrlData", {
            getConceptData: "getConceptDataByXbrlId",
            getTagById: "getTagById",
            getXbrlIdsByConceptId: "getXbrlIdsByConceptId",
            monetaryUnits: "getReportMonetaryUnits"
        }),
        shouldDisableButtons: function() {
            return this.editorSelection.empty;
        },
        isTextSelection: function() {
            if(this.editorSelection instanceof TextSelection) {
                return true;
            } else {
                return false;
            }
        },
        isTextTypeOrTextBlockSelected: function() {
            if(this.factData.type == "string" || this.factData.type == "block") {
                return true;
            } else {
                return false;
            }
        },
        isContinuation: function() {
            if (this.factData) {
                return this.factData.continue;
            }
            return false;
        },
        isCellSelection: function() {
            let result = false;
            if (this.editorSelection && this.editorSelection instanceof NodeSelection) {
                if(this.editorSelection?.node?.type?.name === "tableRow") {
                        result = true;
                }
            };
            return result;
        },
        isEmptySelection: function() {
            return this.editorSelection?.from === this.editorSelection?.to;
        },
        closestParentIsTableRow: function() {
            let parentNode = findParentNodeClosestToPos(this.editorSelection.$from, (node) => {
                if(node.type.name === "tableRow") {
                        return true;
                }
            });
            if(parentNode) {
                return true;
            } else {
                return false;
            }
        },
        highlightedItems: {
            get() {
                let highlightedItems = [];
                let tags = JSON.parse(JSON.stringify(this.reportTags));
                for (let [index,tag] of Object.entries(tags)) {
                    index;
                    if(tag?.type != "table") {
                        highlightedItems.push(tag.id);
                    } else {
                        if(tag?.rows) {
                            for(let [indx,row] of Object.entries(tag?.rows)) {
                                indx;
                                highlightedItems.push(row.id);
                            }
                        }
                    }
                }
                return highlightedItems;
            },
            set() { }
        },
        unitsItems: function(){
            let units = [];
            const storeUnits = this.$store.state.xbrlData.units;
            for (const header of Object.keys(storeUnits)){
                units.push({header: header.replace(header[0], header[0].toUpperCase())})
                units = units.concat(storeUnits[header]);
                units.push({divider: true});
            }

            return units;
        },
        selectedConcept(){
            if (!this.selectedId) return null
            let concept = this.getConceptData(this.selectedId)
            if (!concept) return null
            let references = concept.references
            this.$set(concept, 'displayRefs', {})
            if (references){
                let refs = []
                for (let ref in references){
                    let values = references[ref]
                    for (let [name, val] of values){
                        refs.push([name, val])
                        if (name=="URI") break
                    }
                    this.$set(concept.displayRefs, ref, {'details': refs, expanded: false})
                }
                
            }
            return concept
        }
    },
    watch: {
        openNodes: function(newItems, oldItems){
            if (newItems.length > oldItems.length){ 
                let difference = newItems.filter(item => !oldItems.includes(item))
                if(difference && difference.length == 1) {
                    let parent = this.getNodeById(difference[0], { concepts: this.taxonomies.pre });
                    let childrenNodes =  this.getAllChildrenNodes(parent);
                    let toAdd = [];
                    for(let idx = 0; idx < childrenNodes.length; idx++) {
                            if(!oldItems.includes(childrenNodes[idx])) {
                                toAdd = [...toAdd, childrenNodes[idx]];
                            }
                    }
                    this.openNodes.push(...toAdd);
                }
            }
        },
        selectionAttributes: {
            immediate: true,
            handler: function() {
                if (this.taxonomies.pre) {
                    if (this.highlightedItems.length > 0 ){
                            if(this.selectionAttributes.marks && (this.selectionAttributes.marks.length > 0)) {
                                for(let mark of this.selectionAttributes.marks) {
                                    let tag = this.reportTags[mark];
                                    if(tag != null || tag != undefined) {
                                        this.openHighlightedNode(tag);
                                        this.$nextTick(() => {
                                            setTimeout(() => {
                                                this.$vuetify.goTo(`#id-${tag.id}`, { container: ".tree-view-container"});
                                            }, 500);
                                        })
                                    }
                                }
                            }
                            if(this.selectionAttributes.row && this.selectionAttributes.table) {
                                let tag = this.reportTags[this.selectionAttributes.table].rows[this.selectionAttributes.row];
                                if(tag != null && tag != undefined) {
                                    this.openHighlightedNode(tag);
                                    this.$nextTick(() => {
                                        setTimeout(() => {
                                            this.$vuetify.goTo(`#id-${tag.id}`, { container: ".tree-view-container"});
                                        }, 500);
                                    })
                                }
                            }
                            if(this.selectionAttributes.textBlock){
                                let tag = this.reportTags[this.selectionAttributes.textBlock];
                                if(tag != null || tag != undefined) {
                                    this.openHighlightedNode(tag);
                                    this.$nextTick(() => {
                                        setTimeout(() => {
                                            this.$vuetify.goTo(`#id-${tag.id}`, { container: ".tree-view-container"});
                                        }, 500);
                                    })
                                }
                            }
                    }
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations(["setGlobalMessage"]),
        ...mapMutations("xbrlData",["setReportBaseInfo"]),
        closeDetailsMenu() {
            this.selectedId = null;
        },
        filterTaxonomies(item){
            let concept = this.taxonomies.concepts[item.id];
            if(concept && (concept.name.toLowerCase().includes(this.search.toLowerCase()) || concept.label.toLowerCase().includes(this.search.toLowerCase()) || concept.xId.toLowerCase().includes(this.search.toLowerCase()))){
                this.$refs.treeView.updateAll(true);
                return true;
            } 
        },
        getNodeById(id, node){
            if (node){
                if (node.id === id){
                    return node;
                }else{
                    if ( node.concepts && node.concepts.length > 0) {
                        for (let child of node.concepts){
                            let found = this.getNodeById(id, child);
                            if (found){
                                return found;
                            }
                        }
                    }
                }
            }
            return false;
        },
        closeSideMenu() {
            this.$store.dispatch('xbrlData/closeSideMenu');
        },
        handleClickOnRemoveTagBtn(item) {
            if(this.selectionAttributes.marks && this.selectionAttributes.marks.length > 0) {
                for(let mark of this.selectionAttributes.marks) {
                    let tag = this.reportTags[mark];
                    if((tag != null || tag != undefined) && (tag.id === item.id)) {
                        this.$emit("onRemoveTag",mark);
                    }
                }
            } else if(this.selectionAttributes.row) {
                let tag = this.reportTags[this.selectionAttributes.table].rows[this.selectionAttributes.row];
                if((tag != null || tag != undefined) && (tag.id === item.id)) {
                    this.$emit("onRemoveTag",this.selectionAttributes.row);
                }
            } else if(this.selectionAttributes.textBlock) {
                let tag = this.getTagById(this.selectionAttributes.textBlock);
                if((tag != null || tag != undefined) && (tag.id === item.id)) {
                    this.$emit("onRemoveTag",this.selectionAttributes.textBlock);
                }
            }
        },
        closeDialog() {
            this.dialog = false;
            this.resetDialogFields();
            this.editMode = false;
            this.selectedMark = '';
        },
        openDialog(item, continuation=false) {
            this.selectedItem = item;
            this.dialog = true;
            this.factData.continue = continuation
            this.factData.type = "num"
            let concept = this.getConceptData(item.id)
            switch (concept.type.split(":")[1]){
                case "monetaryItemType":
                    this.factData.type = "monetary"
                    break
                case "stringItemType":
                    this.factData.type = "string"
                    break
                case "textBlockItemType":
                    this.factData.type = "block"
            }

            
            if ( this.selectionAttributes.marks && this.selectionAttributes.marks.length > 0) {
                for(let mark of this.selectionAttributes.marks) {
                        let tag = this.reportTags[mark];
                        if((tag != null || tag != undefined) && tag.id === item.id) {
                            this.setSelectorsData(tag);
                            this.editMode = true;
                            this.selectedMark = mark;
                            this.checkUsedUnitType(tag);
                        }
                }
            } else if (this.selectionAttributes.textBlock) {
                    let tag = this.reportTags[this.selectionAttributes.textBlock];
                    if(tag != null && tag != undefined && tag.id === item.id) {
                        this.setSelectorsData(tag);
                        this.editMode = true;
                        this.selectedBlock = tag;
                        this.checkUsedUnitType(tag);
                    }
            } else {
                if (this.selectionAttributes.row && this.selectionAttributes.table) {
                    let tag = this.reportTags[this.selectionAttributes.table].rows[this.selectionAttributes.row];
                    if(tag != null && tag != undefined && tag.id === item.id) {
                        this.setSelectorsData(tag);
                        this.checkUsedUnitType(tag);
                    }
                }
            }
        
            
        },
        resetDialogFields() {
            let initialData = {
                reverseSign: false,
                period: "",
                periodType: "",
                decimals: null,
                numericScale: null,
                monetaryUnit: null,
                unit: null,
                complexUnit: null,
                numericTransform: "",
                type: "",
                dimensions: [],
            };
            this.factData = initialData;
            this.usedUnit = null
        },
        resetUnits(){
            this.usedUnit = null
            this.factData.monetaryUnit = null
            this.factData.unit = null
            this.factData.complexUnit = null
        },
        openHighlightedNode(tag) {
            for(let item of this.taxonomies.pre) {
                let childrens = this.getAllChildrenNodes(item);
                childrens.find(elem => {
                    if(elem.id === tag.id) {
                        this.openNodes.push(...childrens.map(child => child.id));
                    }
                });
            }
        },
        setSelectorsData(tag) {
            this.factData.reverseSign = tag.attrs.reverseSign;
            this.factData.period = tag.attrs.period;
            this.factData.periodType = tag.attrs.periodType;
            this.factData.decimals = tag.attrs.decimals;
            this.factData.numericScale = tag.attrs.numericScale;
            this.factData.monetaryUnit = tag.attrs.monetaryUnit;
            this.factData.unit = tag.attrs.unit;
            this.factData.complexUnit = tag.attrs.complexUnit;
            this.factData.numericTransform = tag.attrs.numericTransform;
            this.factData.type = tag.attrs.type;
            this.factData.continue = tag.attrs.continue;
            this.factData.dimensions = tag.attrs.dimensions ?? [];
        },
        addDimension(){
            this.factData.dimensions.push({
                dimensionTagName: "",
                dimensionTagValue: "",
            })
        },
        removeDimension(idx){
            this.factData.dimensions.splice(idx, 1)
        },  
        findUnit(item, queryText) {
            const searchText = queryText.toLowerCase();
            return item.name?.toLowerCase().indexOf(searchText) > -1 || item.id?.toString().toLowerCase().indexOf(searchText) > -1;
        },
        showContinueButton(item){
            let reportTag = pickBy(this.reportTags, (tag,)=> tag.id == item.id)
            return Object.keys(reportTag).length > 0
        },
        conceptIsAbstract(item){
            const concept = this.taxonomies.concepts[item.id]
            return !concept || concept.abstract == 'true';
        },
        showEditAndDeleteButtons(item) {
            if (this.isEmptySelection){
                let mark = this.reportTags[this.selectionAttributes.marks[0]];
                let row = this.reportTags[this.selectionAttributes.table]?.rows[this.selectionAttributes.row];
                let textBlock = this.reportTags[this.selectionAttributes.textBlock];
                if( (mark != null || mark != undefined) && (mark.id === item.id)) {
                    return true;
                }
                if( (row != null || row != undefined) && (row.id === item.id)) {
                    return true;
                }
                if( (textBlock != null || textBlock != undefined) && (textBlock.id === item.id)) {
                    return true;
                }
            }
            return false;
        },
        goToTag(id){
            this.$emit('onGoToTag', id);
        },
        onUnitChange(val, type){
            this.usedUnit = null
            if (!val){
                return
            }
            switch (type){
                case 'monetary':
                    this.usedUnit = val ? 1 : null
                    break
                case 'unit': {
                    if (val.ns != "http://www.xbrl.org/2003/instance"){
                        let ns = this.reportInfo.namespaces.filter((n)=> n.url == val.ns)
                        if (!ns?.length){
                            this.setGlobalMessage({
                                    message: `Namespace ${val.ns} is not defined in report info`,
                                    severity: "warning"
                                })
                            this.$refs.newNamespaceConfirmDialog.open().then(confirm => {
                                if (confirm) {
                                    this.newNamespaceUri = val.ns;
                                    this.displayNamespaceDialog = true;
                                } else {
                                    this.clearMonetaryUnit();
                                }
                            });
                        }
                    }
                        this.usedUnit = val ? 2 : null
                        break
                    }
                case 'complex': {
                    this.usedUnit = val ? 3 : null
                    break
                }
            }
        },
        clearMonetaryUnit() {
            this.factData.unit = null;
            this.usedUnit = null;
        },
        currentSelectedContinuation(itemId) {
            return this.selectionAttributes.marks[0] === itemId || this.selectionAttributes.textBlock === itemId || this.selectionAttributes.row === itemId;
        },
        addNewComplexUnit(unit) {
            let newReportInfo = Object.assign({}, this.reportInfo);
            newReportInfo.complex_units.push(unit);
            this.$apiHttp({
                method: "PUT",
                url: "/report",
                data: newReportInfo
                }).then((response) => {
                    this.setReportBaseInfo(response.data.data);
                }).finally(() => {
                    this.displayAddComplexUnitDialog = false;
                    this.factData.complexUnit = unit;
                    this.usedUnit = 3;
            });
            
        },
        checkUsedUnitType(tag) {
            if(tag.attrs?.unit) {
                this.usedUnit = 2;
            }
            if(tag.attrs?.complexUnit) {
                this.usedUnit = 3;
            }
            if(tag.attrs?.monetaryUnit) {
                this.usedUnit = 1
            }
        },
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        
    }
}
</script>

<style lang="scss" scoped>
    :deep(.sign-switch) {
        .v-input__slot {
            flex-direction: row-reverse;
        }
    }
    :deep() {
        .v-treeview-node__root{
            min-height: unset;
            font-size: 15px;
            height: 25px;
        }
        .v-treeview-node__prepend {
            min-width: 0;
        }

    }

    .v-treeview-node {
        height: 20px;
    }
    .search {
        display: flex;
        justify-content: space-between;
    }
    .blackText {
        color: black;
        font-size: 15px;
    }
    :deep(){
        .v-overlay__content {
            flex-grow: 1;
        }

    }
    .v-overlay{
        align-content: stretch;
        align-items: stretch;
        justify-content: start;
    }

    .tree-view-container{
        overflow-y: auto;
    }

    .infoContainer {
        border-bottom: 1px solid lightgray;
        word-break: break-all;
        margin-bottom: -1px;
        overflow: auto;
    }

    .parent-container{
        overflow-x: auto;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .itemLabel {
        cursor: pointer;
    }
    :deep() {
        .complex-unit-selector {
            .v-input__append-inner {
                align-self: unset;
            }
       }
    }
</style>